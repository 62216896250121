import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./additional-funding.css"
import Grants from "../data/af-grants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import {Link} from "gatsby"

const AdditionalFundingGrants = () => {
    const [grants, setGrants] = useState(Grants.grants)

  return (
    <Layout>
      <SEO title="Grants" />
      <div>
        <div className="container primary-bg">
        <div>
          <Link className="additonalFundingHeader"  to="/additional-funding">Available Funding Sources</Link>
          </div>
         
        <div>
        <label className="additonalFundingTitle">Grants</label>
        </div>
          <hr />
          <section className="container">
            {grants
              .sort((a, b) => (a.entityName > b.entityName ? 1 : -1))
              .map((grant, index) => {
                return (
                  <div className="card">
                      <label className="additonalFundingCardTitle">{grant.entityName}</label>
                      <div>
                        <FontAwesomeIcon
                          className="iconStyle"
                          size="lg"
                          icon={faGlobe}
                        />
                        <a
                          className="additonalFunding"
                          href={grant.link}
                          target="_blank"
                        >
                          {grant.link}
                        </a>
                      </div>
                  </div>
                )
              })}
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default AdditionalFundingGrants
