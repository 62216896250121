export const AFGrants = {
    grants: [
      
        
      {
        "entityName": "SOBEWFF® & FIU Chaplin School Hospitality Industry Relief Fund",
        "link": "https://hospitality.fiu.edu/",
      },
        
      {
        "entityName": "North Miami CRA Grant Program",
        "link": "https://tinyurl.com/NMCRA-Grant-Apply",
      },
    ],
  }
  
  export default AFGrants
  
